import React from "react"
import { SiUpwork } from "react-icons/si"
import { FaLinkedinIn, FaTwitter } from "react-icons/fa"
import { GoMail } from "react-icons/go"

const data = [
  {
    id: 1,
    icon: <GoMail className="social-icon"></GoMail>,
    url: "mailto:support@flexhub.tech",
  },
  {
    id: 2,
    icon: <FaLinkedinIn className="social-icon"></FaLinkedinIn>,
    url: "https://www.linkedin.com/in/ehtishamsajjad/",
  },
  {
    id: 3,
    icon: <FaTwitter className="social-icon"></FaTwitter>,
    url: "https://www.twitter.com/ehtishamsajjad",
  },
  {
    id: 4,
    icon: <SiUpwork className="social-icon"></SiUpwork>,
    url: "https://www.upwork.com/o/profiles/users/~012108938cbacd80a5/",
  },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a
        href={link.url}
        className="social-link"
        target="_blank"
        rel="noreferrer"
      >
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
